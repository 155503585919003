import md5 from 'js-md5';
export default function(phone){
		//console.log(md5(option.phone))
		let phoneStr = phone.toString()
		// Before and after
		let before = phoneStr.substring(0,4)
		let after = phoneStr.substring(11,7)
		let center = phoneStr.substring(7,4)
		let sign = md5(md5(before +'https://admin.ruzhiyuan.com/' + after) + center)
		return sign
}